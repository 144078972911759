import React from "react";

const AbsenceIcon = (props) => (  
    <svg {...props} className={"icon"} width="108" height="133" viewBox="0 0 108 133" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.2773 50.9173H54.0015" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M25.2773 67.7801H54.0015" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M25.2773 84.4983H54.0015" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M25.2773 101.671H54.0015" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M70.1172 49.3163L73.0915 52.8741L82.3849 45.3923" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M70.8516 79.7705L79.496 88.6339" stroke="#FF592C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M70.8516 88.6339L79.496 79.7705" stroke="#FF592C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M70.1172 65.7501L73.0915 69.3184L82.3849 61.8366" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M70.1172 100.582L73.0915 104.14L82.3849 96.6584" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M78.7809 4.68192H31.2812C30.1767 4.68192 29.2812 5.57735 29.2812 6.68192V23.2542C29.2812 24.3588 30.1767 25.2542 31.2813 25.2542H78.7809C79.8855 25.2542 80.7809 24.3588 80.7809 23.2542V6.68192C80.7809 5.57735 79.8855 4.68192 78.7809 4.68192Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M82.7791 8.6687C81.6745 8.6687 80.7791 9.56413 80.7791 10.6687V19.0477C80.7791 20.1522 81.6745 21.0477 82.7791 21.0477H91.5202C92.6247 21.0477 93.5202 21.9431 93.5202 23.0477V114.689C93.5202 115.794 92.6247 116.689 91.5202 116.689H16.4799C15.3754 116.689 14.4799 115.794 14.4799 114.689V23.0477C14.4799 21.9431 15.3754 21.0477 16.4799 21.0477H27.2794C28.384 21.0477 29.2794 20.1522 29.2794 19.0477V10.6687C29.2794 9.56413 28.384 8.6687 27.2794 8.6687H6.25C5.14543 8.6687 4.25 9.56413 4.25 10.6687V127.068C4.25 128.173 5.14543 129.068 6.25 129.068H101.75C102.855 129.068 103.75 128.173 103.75 127.068V10.6687C103.75 9.56413 102.855 8.6687 101.75 8.6687H82.7791Z" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M43.1211 14.5495H66.9361" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round"/>
  </svg>
);

export default AbsenceIcon;
