import React from "react";

const AccumIcon = (props) => (
  <svg {...props} className={"icon"} width="154" height="95" viewBox="0 0 154 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.8315 32.5888H4.75391C4.20162 32.5888 3.75391 33.0366 3.75391 33.5888V46.1716C3.75391 46.7239 4.20162 47.1716 4.75391 47.1716H41.8315C42.3838 47.1716 42.8315 46.7239 42.8315 46.1716V33.5888C42.8315 33.0366 42.3838 32.5888 41.8315 32.5888Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M44.7885 47.1716H7.71094C7.15865 47.1716 6.71094 47.6193 6.71094 48.1716V60.7544C6.71094 61.3067 7.15865 61.7544 7.71094 61.7544H44.7885C45.3408 61.7544 45.7885 61.3067 45.7885 60.7544V48.1716C45.7885 47.6193 45.3408 47.1716 44.7885 47.1716Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M42.5737 61.7544H5.49609C4.94381 61.7544 4.49609 62.2021 4.49609 62.7544V75.3372C4.49609 75.8895 4.94381 76.3372 5.4961 76.3372H42.5737C43.1259 76.3372 43.5737 75.8895 43.5737 75.3372V62.7544C43.5737 62.2021 43.1259 61.7544 42.5737 61.7544Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M46.3471 76.3372H9.26953C8.71725 76.3372 8.26953 76.7849 8.26953 77.3372V89.92C8.26953 90.4723 8.71725 90.92 9.26953 90.92H46.3471C46.8994 90.92 47.3471 90.4723 47.3471 89.92V77.3372C47.3471 76.7849 46.8994 76.3372 46.3471 76.3372Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M142.796 3.42838H105.719C105.166 3.42838 104.719 3.87609 104.719 4.42838V17.0112C104.719 17.5635 105.166 18.0112 105.719 18.0112H142.796C143.349 18.0112 143.796 17.5635 143.796 17.0112V4.42838C143.796 3.87609 143.349 3.42838 142.796 3.42838Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M140.581 18.0112H103.504C102.952 18.0112 102.504 18.4589 102.504 19.0112V31.594C102.504 32.1463 102.952 32.594 103.504 32.594H140.581C141.134 32.594 141.581 32.1463 141.581 31.594V19.0112C141.581 18.4589 141.134 18.0112 140.581 18.0112Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M145.21 32.5888H108.133C107.581 32.5888 107.133 33.0366 107.133 33.5888V46.1716C107.133 46.7239 107.581 47.1716 108.133 47.1716H145.21C145.763 47.1716 146.21 46.7239 146.21 46.1716V33.5888C146.21 33.0366 145.763 32.5888 145.21 32.5888Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M148.078 47.1716H111C110.448 47.1716 110 47.6193 110 48.1716V60.7544C110 61.3067 110.448 61.7544 111 61.7544H148.078C148.63 61.7544 149.078 61.3067 149.078 60.7544V48.1716C149.078 47.6193 148.63 47.1716 148.078 47.1716Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M145.21 61.7544H108.133C107.581 61.7544 107.133 62.2021 107.133 62.7544V75.3372C107.133 75.8895 107.581 76.3372 108.133 76.3372H145.21C145.763 76.3372 146.21 75.8895 146.21 75.3372V62.7544C146.21 62.2021 145.763 61.7544 145.21 61.7544Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M149.246 76.3372H112.168C111.616 76.3372 111.168 76.7849 111.168 77.3372V89.92C111.168 90.4723 111.616 90.92 112.168 90.92H149.246C149.798 90.92 150.246 90.4723 150.246 89.92V77.3372C150.246 76.7849 149.798 76.3372 149.246 76.3372Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M95.7924 47.1716H58.7148C58.1626 47.1716 57.7148 47.6193 57.7148 48.1716V60.7544C57.7148 61.3067 58.1626 61.7544 58.7148 61.7544H95.7924C96.3447 61.7544 96.7924 61.3067 96.7924 60.7544V48.1716C96.7924 47.6193 96.3447 47.1716 95.7924 47.1716Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M93.4057 61.7544H56.3281C55.7758 61.7544 55.3281 62.2021 55.3281 62.7544V75.3372C55.3281 75.8895 55.7758 76.3372 56.3281 76.3372H93.4057C93.958 76.3372 94.4057 75.8895 94.4057 75.3372V62.7544C94.4057 62.2021 93.958 61.7544 93.4057 61.7544Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
    <path d="M96.5268 76.3372H59.4492C58.8969 76.3372 58.4492 76.7849 58.4492 77.3372V89.92C58.4492 90.4723 58.8969 90.92 59.4492 90.92H96.5268C97.0791 90.92 97.5268 90.4723 97.5268 89.92V77.3372C97.5268 76.7849 97.0791 76.3372 96.5268 76.3372Z" fill="#F5A506" stroke="var(--svgColor)" strokeWidth="3" strokeMiterlimit="10"/>
  </svg>
);

export default AccumIcon;
